import React from "react";
import Page from "../components/Layout/Page";
import { Query } from "..";
import GatsbyImage, { FluidObject } from "gatsby-image";
import ExternalLink from "../components/Elements/ExternalLink";
import pageColors from "../data/pageColors";
import { WEBCOMIC_PANE, WEBCOMIC_STATISTICS } from "../routes";
import { graphql, Link } from "gatsby";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";

const SEASON_2_KEY = 41;
const LAST_PAGE = 55;

const COLOR = pageColors.webcomic;
const language = "fr";

const Metadata = ({ children, color = COLOR, className = "" }) => (
    <div
        className={`mb-5 bg-${color}-600 text-white font-sans p-3 text-right font-hairline rounded shadow-3xl ${className}`}
        style={{ width: 500, maxWidth: "100%" }}
    >
        {children}
    </div>
);

const MetadataHeader = ({ children }) => (
    <h1
        className={
            "m-0 text-2xl mb-3 text-white lowercase font-sans text-shadow"
        }
    >
        {children}
    </h1>
);

const SeasonButton = ({
    season,
    active,
    className = "",
}: {
    season: number;
    active: number;
    className: string;
}) => {
    const shade = active === season ? 500 : 600;
    return (
        <Link
            to={WEBCOMIC_PANE(season === 2 ? SEASON_2_KEY : 1)}
            className={`px-4 py-1 bg-${COLOR}-${shade} hover:bg-${COLOR}-${
                shade - 100
            } ${className}`}
        >
            {season}
        </Link>
    );
};

const Pane = ({ image }) => (
    <GatsbyImage
        fluid={image.childImageSharp.fluid as FluidObject}
        className={"rounded shadow-2xl mb-5 w-full"}
        style={{
            width: image.childImageSharp.fluid.presentationWidth,
            maxWidth: "100%",
        }}
    />
);

const WebcomicPane = ({ data }: { data: Query }) => {
    const currentPane = data.webcomicPage;
    const season = currentPane.key < SEASON_2_KEY ? 1 : 2;

    const NavigationButton = ({
        children,
        to,
        onChangePane,
        className = "",
    }: {
        children: any;
        to?: string;
        onChangePane?: Function;
        className?: string;
    }) => {
        const url =
            to ||
            WEBCOMIC_PANE(
                Math.min(Math.max(1, onChangePane(currentPane.key)), LAST_PAGE),
            );

        return (
            <Link
                to={url}
                className={`text-white flex-1 mb-3 hover:text-gray-600 ${className}`}
            >
                {children}
            </Link>
        );
    };

    return (
        <Page title={pageTitles.webcomic} layoutClassName={"bg-gray-800"}>
            <PageHeader
                color={COLOR}
                padding={10}
                counts={[
                    <div className="sticky top-0 flex w-full space-between font-bold text-2xl lowercase text-shadow flex-col md:flex-row">
                        <span className="flex-1">
                            <span className="mr-5">Season</span>
                            <SeasonButton
                                season={1}
                                active={season}
                                className={"rounded-l-lg"}
                            />
                            <SeasonButton
                                season={2}
                                active={season}
                                className={"rounded-r-lg"}
                            />
                        </span>
                        <span className={"flex-1"}>
                            <NavigationButton onChangePane={(pane) => pane - 1}>
                                &laquo; Previous
                            </NavigationButton>
                            <span className="mx-5">
                                <NavigationButton onChangePane={(pane) => pane}>
                                    {String(currentPane.key).padStart(3, "0")}
                                </NavigationButton>
                            </span>

                            <NavigationButton
                                className={"text-right"}
                                onChangePane={(pane) => pane + 1}
                            >
                                Next &raquo;
                            </NavigationButton>
                        </span>
                        <Link
                            to={WEBCOMIC_STATISTICS}
                            className={"flex-1 hover:text-gray-600"}
                        >
                            Statistics
                        </Link>
                    </div>,
                ]}
            >
                <strong>On a verrouillé ma porte d'entrée</strong>{" "}
                <em>(We locked my front door)</em> is an interactive webcomic
                that was active from 2007 to 2010 on the{" "}
                <ExternalLink
                    href="https://openclassrooms.com/forum/sujet/on-a-verrouille-ma-porte-d-entree-70927"
                    className={"text-white"}
                >
                    OpenClassrooms forums
                </ExternalLink>
                .<br />A couple images were made as a starter then people would
                suggest what to do next and I'd draw the next panes.
            </PageHeader>

            <div className="bg-gray-800 pt-5 flex-col items-center xl:flex-row flex xl:items-start justify-center">
                <div
                    className={
                        "flex-1 flex flex-col items-end justify-start w-full p-5"
                    }
                >
                    {currentPane.suggestions.length > 0 && (
                        <>
                            <MetadataHeader>Suggestions</MetadataHeader>
                            {currentPane.suggestions.map((suggestion) => (
                                <Metadata
                                    key={suggestion.author}
                                    color={"green"}
                                >
                                    {suggestion[language]}
                                    <footer className={"text-xs mt-2"}>
                                        suggested by{" "}
                                        <strong>{suggestion.author}</strong>
                                    </footer>
                                </Metadata>
                            ))}
                        </>
                    )}
                </div>
                <div className="flex justify-center items-center flex-col px-5 w-full">
                    {currentPane.narration.length > 0 &&
                        currentPane.narration.map((sentence) => (
                            <Metadata key={sentence[language]}>
                                <em>{sentence[language]}</em>
                            </Metadata>
                        ))}
                    {currentPane.panes.map((pane) => (
                        <Pane key={pane.id} image={pane} />
                    ))}
                </div>
                <div
                    className={
                        "flex-1 flex flex-col p-5 items-start justify-end w-full"
                    }
                >
                    {currentPane.inventory && (
                        <>
                            <MetadataHeader>Inventory</MetadataHeader>
                            <Pane image={currentPane.inventory} />
                        </>
                    )}
                    {currentPane.map && (
                        <>
                            <MetadataHeader>Map</MetadataHeader>
                            <Pane image={currentPane.map} />
                        </>
                    )}
                </div>
            </div>
        </Page>
    );
};

export const query = graphql`
    fragment PaneImage on File {
        childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
                ...GatsbyImageSharpFluid
                presentationWidth
            }
        }
    }
    query PaneQuery($key: Int) {
        webcomicPage(key: { eq: $key }) {
            key
            inventory {
                ...PaneImage
            }
            map {
                ...PaneImage
            }
            panes {
                id
                relativeDirectory
                name
                ...PaneImage
            }
            suggestions {
                fr
                author
            }
            narration {
                fr
            }
        }
    }
`;

export default WebcomicPane;
